<template>
  <div v-if="loaded && step" class="resting-step-container">
    <!-- eslint-disable vue/no-v-html -->
    <h2 class="step-heading pb-4 mt-0" v-html="stepHeading" />
    <!--eslint-enable-->
    <div v-if="step.image_urls.length && step.description">
      <div v-if="step.layout_format === 'image-left'" :class="'horz ' + layoutFormat">
        <div class="image">
          <img :src="imageUrl" :alt="step.image_alt_text">
        </div>
        <div class="description">
          <!-- eslint-disable vue/no-v-html -->
          <div v-html="stepDescription" />
          <!--eslint-enable-->
        </div>
      </div>

      <div v-if="step.layout_format === 'image-right'" :class="'horz ' + layoutFormat">
        <div class="description">
          <!-- eslint-disable vue/no-v-html -->
          <div v-html="stepDescription" />
          <!--eslint-enable-->
        </div>
        <div class="image">
          <img :src="imageUrl" :alt="step.image_alt_text">
        </div>
      </div>

      <div v-if="step.layout_format === 'image-top'" :class="'vert ' + layoutFormat">
        <div class="image">
          <img :src="imageUrl" :alt="step.image_alt_text">
        </div>
        <div class="description">
          <!-- eslint-disable vue/no-v-html -->
          <div v-html="stepDescription" />
          <!--eslint-enable-->
        </div>
      </div>

      <div v-if="step.layout_format === 'image-bottom'" :class="'vert ' + layoutFormat">
        <div class="description">
          <!-- eslint-disable vue/no-v-html -->
          <div v-html="stepDescription" />
          <!--eslint-enable-->
        </div>
        <div class="image">
          <img :src="imageUrl" :alt="step.image_alt_text">
        </div>
      </div>
    </div>

    <div v-else-if="step.description">
      <div class="description">
        <!-- eslint-disable vue/no-v-html -->
        <div v-html="stepDescription" />
        <!--eslint-enable-->
      </div>
    </div>
    <div v-else-if="step.image_urls.length">
      <div class="image-vert">
        <img :src="imageUrl" :alt="step.image_alt_text">
      </div>
    </div>
  </div>

  <div v-else>
    <div class="d-flex justify-content-center mt-2 mb-2">
      <b-spinner variant="primary" large />
    </div>
  </div>
</template>

<script>
  import * as DOMPurify from 'dompurify'

  export default {
    name: 'RestingStep',
    components: {  },
    props: {
      step: null,
    },
    data() {
      return {
        loaded: false,
      }
    },
    computed: {
      imageUrl() {
        return this.step.image_urls[0]
      },
      layoutFormat() {
        return this.step.layout_format
      },
      stepHeading() {
        return DOMPurify.sanitize(this.step.heading)
      },
      stepDescription() {
        return DOMPurify.sanitize(this.step.description)
      },
    },
    mounted() {
      this.loaded = true
    },
  }
</script>
<style scoped lang="scss">
  .resting-step-container {
    max-width: 900px;
    .horz {
      display: flex;
      flex-direction: row;
      flex-shrink: 1;
      justify-content: center;
      > :first-child {
        margin-right: 30px;
      }
      .image {
        flex: 0 1 50%;
        img {
          width: 100%;
        }
      }
      .description {
        width: 50%;
        display: flex;
        flex: 0 1 50%;
        flex-direction: column;
        justify-content: center;
        margin: 0;
        div {
          margin: 0;
        }
      }
    }
    .vert {
      display: flex;
      flex-direction: column;
      justify-content: flex-start;
      align-items: center;
      :first-child {
        margin-bottom: 30px;
      }
      .image {
        margin: 0 auto;
        img {
          width: 100%;
        }
      }
    }
    .image-vert {
      text-align: center;
      height:400px;
      img {
        height: 100%;
      }
    }

  }

  @media only screen and (max-width: 660px) {
    .resting-step-container {
      width: 100%;

      .horz {
        .description {
          width: 100%;
          flex: 0 0 100%;
        }

        .image {
          display: none;
        }
      }

      .image-vert {
        height: 100%;

        img {
          width: 100%;
        }
      }
    }
  }
</style>
