<template>
  <b-container v-if="loaded && !selectedProductConfirmed"
               id="product-step"
               class="container-fluid"
  >
    <h3>{{ currentStep.heading }}</h3>

    <br>

    <div class="step-description mb-2 justify-content-center">
      <div v-html="currentStepDescription" />
    </div>

    <b-row v-if="loaded && stepProductsToUse">
      <b-card-group deck :class="{ horizontal }">
        <product-card
          v-for="sp in stepProductsToUse"
          :key="sp.id"
          :step-product="sp"
          :disabled="isDisabled(sp)"
          :step-jurisdiction="jurisdiction"
          @chosen="chooseProductHandler(sp)"
        />
      </b-card-group>
    </b-row>

    <ct-centered-spinner v-else />

    <product-options-confirm-modal
      :bus="bus"
      :loading="!loaded || loading"
      :company="company"
      @complete="productOptionConfirmed"
    />
  </b-container>
</template>

<script>
  import { mapGetters, mapActions } from 'vuex'
  import ProductCard from '../../cards/ProductCard'
  import ProductOptionsConfirmModal from '@/components/StagelineV1/modals/ProductOptionsConfirmModal'
  import CtCenteredSpinner from '@/components/shared/CtCenteredSpinner'
  import Vue from 'vue'
  import * as DOMPurify from 'dompurify'

  export default {
    name: 'ProductStep',
    components: {
      ProductCard,
      ProductOptionsConfirmModal,
      CtCenteredSpinner,
    },
    props: {
      stepProducts: Array,
    },
    data() {
      return {
        loaded: false,
        loading: false,
        selectedStepProduct: {},
        submittingSelectedProduct: false,
        selectedProductConfirmed: false,
        stepProductsToUse: this.stepProducts,
        bus: new Vue,
      }
    },
    computed: {
      ...mapGetters('stageline', [
        'jurisdiction',
        'company',
        'currentStep',
      ]),
      ...mapGetters('checkout', [
        'findCartItem',
        'productsLoaded',
      ]),
      ...mapGetters('companies', [
        'hasExistingRAServiceInJurisdiction',
      ]),
      horizontal() {
        return this.stepProductsToUse.length > 3
      },
      currentStepDescription() {
        return DOMPurify.sanitize(this.currentStep.description)
      },
    },
    async mounted() {
      if (this.hasExistingRAServiceInJurisdiction(this.company.domestic_registration?.jurisdiction)) {
        this.stepProductsToUse = this.stepProducts.filter(p => p.product_category !== 'free form a company')
      }

      if (!this.productsLoaded) {
        await this.loadProducts()
      }

      this.loaded = true
    },
    methods: {
      ...mapActions('checkout', [
        'addToCart',
        'loadProducts',
        'setupContext',
      ]),
      async chooseProductHandler(sp) {
        this.selectedStepProduct = sp

        if (sp.product_kind === 'diy') {
          this.$emit('proceed-to-next-step')
          return
        }
        this.bus.$emit('open-product-options-confirm', [sp.product])
      },
      productOptionConfirmed() {
        this.$emit('proceed-to-next-step')
      },
      isDisabled(_product) {
        // TODO implement maybe
        return false
      },
    },
  }
</script>

<style scoped lang="scss">
#product-step {
  h3 {
    text-align: left;
  }
  .card-deck {
    width: 100%;
    justify-content: center;
    &.horizontal {
      flex-flow: row wrap;
      flex-grow: 0;
      .card {
        min-height: unset;
        max-height: unset;
        min-width: unset;
        max-width: unset;
        height: 280px;
        flex: 0 0 450px;
        flex-flow: row;
        .card-body {
          text-align: left;
          .card-title {
            text-align: center;
          }
        }
        .card-footer {
          display: flex;
          flex-flow: column;
          justify-content: center;
          min-width: 180px;
          button {
            width: 100%;
          }
          border-left: $ct-ui-border-1 solid $ct-ui-color-22;
          margin-top: 24px;
          margin-bottom: 24px;
        }
      }
    }
  }
}
</style>
